import React from "react";
import { FormattedHTMLMessage, useIntl } from "gatsby-plugin-intl";
import logoWhite from "../../images/logo-white.png";
import fwdLogo from "../../images/shopee/logo.svg";
import elecboyLogo from "../../images/elecboy/logo.svg";
import btLogo from "../../images/eir/bt_logo.svg";
import { PARTNER_COUNTRY, PARTNER_DETAILS } from "../../utils/constants";
import "./footer.scss";
import { Link } from "gatsby";

function Footer() {
  const intl = useIntl();
  const isShopee = process.env.GATSBY_PARTNER === PARTNER_DETAILS.SHOPEE;
  //const isEir = process.env.GATSBY_PARTNER === PARTNER_DETAILS.EIR;
  const isElecboy = process.env.GATSBY_PARTNER === PARTNER_DETAILS.ELECBOY;
  const shopeePrivacyPolicy = isShopee && intl.locale === 'en' ? process.env.GATSBY_PRIVACY_POLICY_EN : process.env.GATSBY_PRIVACY_POLICY_TH
  const shopeeDisclaimer = isShopee && intl.locale === 'en' ? process.env.GATSBY_DISCLAIMER_EN : process.env.GATSBY_DISCLAIMER_TH
  const elecboyPrivacyPolicy = isElecboy && intl.locale === 'en' ? process.env.GATSBY_PRIVACY_POLICY_EN : process.env.GATSBY_PRIVACY_POLICY_HK
  const elecboyDisclaimer = isElecboy && intl.locale === 'en' ? process.env.GATSBY_DISCLAIMER_EN : process.env.GATSBY_DISCLAIMER_HK
  const isMyBnm = process.env.GATSBY_PARTNER === PARTNER_DETAILS.BOLTTECH && (process.env.GATSBY_COUNTRY === PARTNER_COUNTRY.MY || process.env.GATSBY_COUNTRY === PARTNER_COUNTRY.MYDIGI)
  const lang = isMyBnm && intl.locale === 'bm' ? 'my' : intl.locale;
  const isSingtel = process.env.GATSBY_PARTNER === PARTNER_DETAILS.SINGTEL;
  const isTre = process.env.GATSBY_PARTNER === PARTNER_DETAILS.TRE;
  const singtelDisclaimer = isSingtel && process.env.GATSBY_DISCLAIMER_EN
  const treDisclaimer = isTre && process.env.GATSBY_DISCLAIMER_EN

  const getDisclaimerUrl = () => {
    if (isElecboy) {
      return elecboyDisclaimer;
    } else if (isSingtel) {
      return singtelDisclaimer;
    } else if (isTre) { 
      return treDisclaimer;
    }
    return process.env.GATSBY_COUNTRY === PARTNER_COUNTRY.HK || process.env.GATSBY_COUNTRY === PARTNER_COUNTRY.MO
  }

  return (
    <section>
      {(process.env.GATSBY_PARTNER !== PARTNER_DETAILS.EIR) ?
      <div className="footer-wrap bg-navy">
        <div className="main-container">
          <div className="footer-flex">
              {
                !isTre && <div className="footer-logo">
                {logoFunct()}
                </div>
              }
            <div className="footer-t-c">
              {isMyBnm ? (
                <>
                  <Link to={`${process.env.GATSBY_TOS}&language=${lang.toUpperCase()}`} className="mr-3"
                  >
                    {intl.formatMessage({ id: "tncLink" })}
                  </Link>
                </>
                ) : (isShopee || isElecboy || isSingtel || isTre) ? <></> : (
              <a
                href={`${process.env.GATSBY_TOS}&language=${intl.locale.toUpperCase()}`}
                rel="noreferrer"
                target="_blank"
                className="pr-4"
              >
                {intl.formatMessage({ id: "tncLink" })}
                </a>)}
              
                {(isElecboy || isSingtel) && <a
                  href={getDisclaimerUrl()}
                rel="noreferrer"
                target="_blank"
                className="pr-4"
              >
                {intl.formatMessage({ id: "disclaimer" })}
              </a>}

              {isElecboy && <a
                href={elecboyPrivacyPolicy}
                rel="noreferrer"
                target="_blank"
                className="pr-4"
              >
                {intl.formatMessage({ id: "privacyPolicy" })}
              </a>}

              {isShopee && <a
                href={shopeePrivacyPolicy}
                rel="noreferrer"
                target="_blank"
                className="pr-4"
              >
                {intl.formatMessage({ id: "privacyPolicy" })}
                </a>}
                
                {isTre && <a
                  href={`${process.env.GATSBY_PRIVACY_POLICY_AND_PRIVACY}${intl.locale.toUpperCase()}.pdf`}
                rel="noreferrer"
                target="_blank"
                className="mr-3"
              >
                {intl.formatMessage({ id: "termsAndPrivacyPolicy" })}
            </a>
                }
              {(!isShopee && !isElecboy && !isSingtel && !isTre) && <a
                href={intl.locale === 'en' ? process.env.GATSBY_PRIVACY_POLICY : `${process.env.GATSBY_PRIVACY_POLICY}&language=${intl.locale.toUpperCase()}`}
                rel="noreferrer"
                target="_blank"
              >
                {intl.formatMessage({ id: "privacyPolicy" })}
              </a>}
            
              {isShopee && <a
                href={shopeeDisclaimer}
                rel="noreferrer"
                target="_blank"
              >
                  {intl.formatMessage({ id: "disclaimer" })}
              </a>}

              </div>
              <div className="footer-t-c-text">
                {intl.formatMessage({ id: "rightsReserved" })}
              </div>
            </div>
          </div>
        </div>
      :
        <div className="eir-footer-wrap bg-white">
          <div>
          <img src={btLogo} alt="logo"/>
          </div>
          <div className="disclaimer">
            <FormattedHTMLMessage id="rightsReserved" />
          </div>
          
          <div className="footer-t-c-text">
            <a
                href={process.env.GATSBY_PRIVACY_POLICY_AND_PRIVACY}
                rel="noreferrer"
                target="_blank"
                className="mr-3"
              >
                {intl.formatMessage({ id: "termsAndPrivacyPolicy" })}
            </a>
            
          </div>
        </div>                  
      }
    </section>
  );
}

const logoFunct = () => {
  if (process.env.GATSBY_PARTNER === 'shopee') {
    return <img src={fwdLogo} alt="logo" />
  } else if (process.env.GATSBY_PARTNER === 'elecboy') {
    return <img src={elecboyLogo} alt="logo" />
  } else {
    return <img src={logoWhite} alt="logo" />
  }
};

export default Footer;
