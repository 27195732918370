export const PARTNER_DETAILS = {
  BOLTTECH: "bdp",
  MTEL: "mtel",
  SAMSUNG: "samsung",
  DREI: "drei",
  SHOPEE: "shopee",
  EIR: "eir",
  ELECBOY: "elecboy",
  HOMECREDIT: "homecredit",
  SINGTEL: "singtel",
  TRE: "tre"
};

export const PARTNER_COUNTRY = {
  MY: 'MY',
  MYDIGI: 'MY-DIGI',
  PH: 'PH',
  AT: 'AT',
  HK: 'HK',
  MO: 'MO',
  TW: 'TW',
  IE: 'IE',
  SG: 'SG',
  SE: 'SE',
};

export const SITE_METADATA = {
  [PARTNER_DETAILS.BOLTTECH]: {
    title: "Service Request",
    keywords: [
      `bolttech`,
      `service portal`,
      `Device protection`,
      `Claim portal`,
    ],
  },
  [PARTNER_DETAILS.MTEL]: {
    title: "Service Request | Mtel",
    keywords: [`Mtel`, `service portal`, `Service request Mtel`],
  },
  [PARTNER_DETAILS.SAMSUNG]: {
    title: "Service Request | Samsung",
    keywords: [`Samsung Claim Request`, `Samsung`, `Bolttech`],
  },
  [PARTNER_DETAILS.DREI]: {
    title: "Service Request | Austria Drei",
    keywords: [`Drei`, `Service request`, `Service request Drei`],
  },
  [PARTNER_DETAILS.SHOPEE]: {
    title: "Service Request | Shopee TH",
    keywords: [`Shopee`, `Service request`, `Service request Shopee`],
  },
  [PARTNER_DETAILS.EIR]: {
    title: "Service Request | Ireland EIR",
    keywords: [`Eir`, `Service request`, `Service request Eir`],
  },
  [PARTNER_DETAILS.TRE]: {
    title: "Service Request | 3 Tre Sweden",
    keywords: [`Tre`, `Service request`, `Service request Tre`],
  },
  [PARTNER_DETAILS.ELECBOY]: {
    title: "Service Request",
    keywords: [`Elecboy`, `service portal`, `Device protection`, `Claim portal`],
  },
  [PARTNER_DETAILS.HOMECREDIT]: {
    title: "Service Request | Homecredit PH",
    keywords: [
      `homecredit`,
      `service portal`,
      `Device protection`,
      `Claim portal`,
    ],
  },
};

export const OTHER_REQ_OPTIONS = {
  [PARTNER_DETAILS.BOLTTECH]: {
    PH: {
      SECTION_HEADING: "otherWaysOfReq",
      WORKING_DAYS: "workingDays",
      CONNECT_OPTIONS: [
        {
          iconName: "fb",
          values: [
            {
              titleTextId: "msgViaFB",
              linkText: { label: "bdpAsia", webLink: "fbPage" },
              linkType: "hyperlink",
              gtmTrackKey: 'FB'
            },
          ],
        },
        {
          iconName: "email",
          values: [
            {
              titleTextId: "emailUs",
              linkText: { label: "supportMailId", webLink: "supportMailId" },
              linkType: "email",
              gtmTrackKey: null
            },
          ],
        },
        {
          iconName: "phone",
          values: [
            {
              titleTextId: "phoneTitle-1",
              linkText: "phoneNum-1",
              linkType: "telephone",
              newPhone1: 'phoneNum-1a',
            },
            {
              titleTextId: "phoneTitle-3",
              linkText: "phoneNum-3",
              linkType: "telephone",
            },
            {
              titleTextId: "phoneTitle-4",
              linkText: "phoneNum-4",
              linkType: "telephone",
            },
          ],
        },
      ],
    },

    MY: {
      SHOW_POPUP: true,
      SECTION_HEADING: "otherWaysOfReq",
      WORKING_DAYS: "workingDays",
      CONNECT_OPTIONS: [
        {
          iconName: "fb",
          values: [
            {
              titleTextId: "msgViaFB",
              linkText: { label: "bdpAsia", webLink: "fbPage" },
              linkType: "hyperlink",
              gtmTrackKey: 'FB'
            },
          ],
        },
        {
          iconName: "email",
          values: [
            {
              titleTextId: "emailUs",
              linkText: { label: "supportMailId", webLink: "supportMailId" },
              linkType: "email",
              gtmTrackKey: null
            },
          ],
        },
        {
          iconName: "phone",
          values: [
            {
              titleTextId: "phoneTitle-1",
              linkText: "phoneNum-1",
              linkType: "telephone",
            },
          ],
        },
      ],
    },

    'MY-DIGI': {
      SHOW_POPUP: true,
      SECTION_HEADING: "otherWaysOfReq",
      WORKING_DAYS: "workingDays",
      CONNECT_OPTIONS: [
        {
          iconName: "fb",
          values: [
            {
              titleTextId: "msgViaFB",
              linkText: { label: "bdpAsia", webLink: "fbPage" },
              linkType: "hyperlink",
              gtmTrackKey: 'FB'
            },
          ],
        },
        {
          iconName: "email",
          values: [
            {
              titleTextId: "emailUs",
              linkText: { label: "supportMailId", webLink: "supportMailId" },
              linkType: "email",
              gtmTrackKey: null
            },
          ],
        },
        {
          iconName: "phone",
          values: [
            {
              titleTextId: "phoneTitle-1",
              linkText: "phoneNum-1",
              linkType: "telephone",
            },
          ],
        },
      ],
    },
    HK: {
      SECTION_HEADING: "otherWaysOfReq",
      WORKING_DAYS: "workingDays",
      CONNECT_OPTIONS: [
        {
          iconName: "email",
          values: [
            {
              titleTextId: "emailUs",
              linkText: { label: "supportMailId", webLink: "supportMailId" },
              linkType: "email",
              gtmTrackKey: null
            },
          ],
        },
        {
          iconName: "phone",
          values: [
            {
              titleTextId: "phoneTitle-1",
              linkText: "phoneNum-1",
              linkType: "telephone",
            },
          ],
        },
      ],
    },
    TW: {
      SECTION_HEADING: "otherWaysOfReq",
      WORKING_DAYS: "workingDays",
      CONNECT_OPTIONS: [
        {
          iconName: "email",
          values: [
            {
              titleTextId: "emailUs",
              linkText: { label: "supportMailId", webLink: "supportMailId" },
              linkType: "email",
              gtmTrackKey: null
            },
          ],
        },
        {
          iconName: "phone",
          values: [
            {
              titleTextId: "phoneTitle-1",
              linkText: "phoneNum-1",
              linkType: "telephone",
            },
          ],
        },
      ],
    },
  },
  [PARTNER_DETAILS.MTEL]: {
    AT: {
      SECTION_HEADING: "otherWaysOfReq",
      WORKING_DAYS: "workingDays",
      CONNECT_OPTIONS: [
        {
          iconName: "email",
          values: [
            {
              titleTextId: "emailUs",
              linkText: { label: "supportMailId", webLink: "supportMailId" },
              linkType: "email",
            },
          ],
        },
        {
          iconName: "phone",
          values: [
            {
              titleTextId: "phoneTitle-1",
              linkText: "phoneNum-1",
              linkType: "telephone",
            },
          ],
        },
      ],
    },
  },
  [PARTNER_DETAILS.DREI]: {
    AT: {
      SECTION_HEADING: "otherWaysOfReq",
      WORKING_DAYS: "workingDays",
      CONNECT_OPTIONS: [
        {
          iconName: "email",
          values: [
            {
              titleTextId: "emailUs",
              linkText: { label: "supportMailId", webLink: "supportMailId" },
              linkType: "email",
            },
          ],
        },
        {
          iconName: "phone",
          values: [
            {
              titleTextId: "phoneTitle-1",
              linkText: "phoneNum-1",
              linkType: "telephone",
            },
            {
              titleTextId: "phoneTitle-2",
              linkText: "phoneNum-2",
              linkType: "telephone",
            },
          ],
        },
      ],
    },
  },
  [PARTNER_DETAILS.SHOPEE]: {
    TH: {
      SECTION_HEADING: "otherWaysOfReq",
      WORKING_DAYS: "workingDays",
      CONNECT_OPTIONS: [
        {
          iconName: "email",
          values: [
            {
              titleTextId: "emailUs",
              linkText: { label: "supportMailId", webLink: "supportMailId" },
              linkType: "email",
            },
          ],
        }
      ],
    },
  },
  [PARTNER_DETAILS.ELECBOY]: {
    HK: {
      SECTION_HEADING: "otherWaysOfReq",
      WORKING_DAYS: "workingDays",
      CONNECT_OPTIONS: [
        {
          iconName: "email",
          values: [
            {
              titleTextId: "emailUs",
              linkText: { label: "supportMailId", webLink: "supportMailId" },
              linkType: "email",
              gtmTrackKey: null
            },
          ],
        },
      ],
    },
  },
  [PARTNER_DETAILS.SAMSUNG]: {
    BE: {
      SHOW_POPUP: false,
      SHOW_POPUP_AND_MODAL: false
    },
    NO: {
      SHOW_POPUP: false,
      SHOW_POPUP_AND_MODAL: false
    },
    NL: {
      SHOW_POPUP: false,
      SHOW_POPUP_AND_MODAL: false
    },
    DK: {
      SHOW_POPUP: false,
      SHOW_POPUP_AND_MODAL: false
    },
    FI: {
      SHOW_POPUP: false,
      SHOW_POPUP_AND_MODAL: false
    },
    SE: {
      SHOW_POPUP: false,
      SHOW_POPUP_AND_MODAL: false
    },
    MY: {
      SHOW_POPUP: true,
      SHOW_POPUP_AND_MODAL: true
    },
    HK: {
      SHOW_POPUP: false
    },
    TH: {
      SHOW_POPUP: false
    },
    MO: {
      SHOW_POPUP: false
    },
    ID: {
      SHOW_POPUP: false
    },
    PH: {
      SHOW_POPUP: false
    }
  },
  [PARTNER_DETAILS.EIR]: {
    IE: {
      SECTION_HEADING: "otherWaysOfReq",
      WORKING_DAYS: "workingDays",
      CONNECT_OPTIONS: [
        {
          iconName: "eir/email",
          values: [
            {
              titleTextId: "emailUs",
              linkText: { label: "supportMailId", webLink: "supportMailId" },
              linkType: "email",
            },
          ],
        },
        {
          iconName: "eir/phone",
          values: [
            {
              titleTextId: "phoneTitle-1",
              linkText: "phoneNum-1",
              linkType: "telephone",
            },
          ],
        },
      ],
    },
  },
  [PARTNER_DETAILS.TRE]: {
    SE: {
      SECTION_HEADING: "otherWaysOfReq",
      WORKING_DAYS: "workingDays",
      CONNECT_OPTIONS: [
        {
          iconName: "tre/email",
          values: [
            {
              titleTextId: "emailUs",
              linkText: { label: "supportMailId", webLink: "supportMailId" },
              linkType: "email",
            },
          ],
        },
        {
          iconName: "tre/phone",
          values: [
            {
              titleTextId: "phoneTitle-1",
              linkText: "phoneNum-1",
              linkType: "telephone",
            },
          ],
        },
      ],
    },
  },
  [PARTNER_DETAILS.HOMECREDIT]: {
    PH: {
      SECTION_HEADING: "otherWaysOfReq",
      WORKING_DAYS: "workingDays",
      CONNECT_OPTIONS: [
        {
          iconName: "fb",
          values: [
            {
              titleTextId: "msgViaFB",
              linkText: { label: "bdpAsia", webLink: "fbPage" },
              linkType: "hyperlink",
              gtmTrackKey: 'FB'
            },
          ],
        },
        {
          iconName: "email",
          values: [
            {
              titleTextId: "emailUs",
              linkText: { label: "supportMailId", webLink: "supportMailId" },
              linkType: "email",
              gtmTrackKey: null
            },
          ],
        },
        {
          iconName: "phone",
          values: [
            {
              titleTextId: "phoneTitle-1",
              linkText: "phoneNum-1",
              linkType: "telephone",
              newPhone1: 'phoneNum-1a',
            },
            {
              titleTextId: "phoneTitle-3",
              linkText: "phoneNum-3",
              linkType: "telephone",
            },
            {
              titleTextId: "phoneTitle-4",
              linkText: "phoneNum-4",
              linkType: "telephone",
            },
          ],
        },
      ],
    },
  }
};

export const GTM_TAGS = {
  [PARTNER_DETAILS.BOLTTECH]: {
    PH: {
      VIBER: {
        CLICK: {
          tagName: 'PH_btn_Landing_Viber',
          category: 'PH_btn_Landing_Viber'
        }
      },
      LIVE_CHAT: {
        CLICK: {
          tagName: 'PH_btn_Landing_LiveChat',
          category: 'PH_btn_Landing_LiveChat'
        }
      },
      SERVICE_PORTAL: {
        CLICK: {
          tagName: 'PH_btn_Landing_CustPortal',
          category: 'PH_btn_Landing_CustPortal'
        }
      },
      FB: {
        CLICK: {
          tagName: 'PH_Btn_Landing_Facebook',
          category: 'PH_Btn_Landing_Facebook'
        }
      }
    },
  },
  [PARTNER_DETAILS.DREI]: {
    AT: {
      LIVE_CHAT: {
        CLICK: {
          tagName: 'AT_Btn_Landing_LiveChat',
          category: 'AT_Btn_Landing_LiveChat'
        }
      },
      SERVICE_PORTAL: {
        CLICK: {
          tagName: 'AT_Btn_Landing_CustPortal',
          category: 'AT_Btn_Landing_CustPortal'
        }
      }
    },
  },

  [PARTNER_DETAILS.SAMSUNG]: {
    BE: {
      LIVE_CHAT: {
        CLICK: {
          tagName: 'BE_Btn_Landing_LiveChat',
          category: 'BE_Btn_Landing_LiveChat'
        }
      },
      SERVICE_PORTAL: {
        CLICK: {
          tagName: 'BE_Btn_Landing_CustPortal',
          category: 'BE_Btn_Landing_CustPortal'
        }
      }
    },
    NL: {
      LIVE_CHAT: {
        CLICK: {
          tagName: 'NL_Btn_Landing_LiveChat',
          category: 'NL_Btn_Landing_LiveChat'
        }
      },
      SERVICE_PORTAL: {
        CLICK: {
          tagName: 'NL_Btn_Landing_CustPortal',
          category: 'NL_Btn_Landing_CustPortal'
        }
      }
    },
    SE: {
      LIVE_CHAT: {
        CLICK: {
          tagName: 'SE_Btn_Landing_LiveChat',
          category: 'SE_Btn_Landing_LiveChat'
        }
      },
      SERVICE_PORTAL: {
        CLICK: {
          tagName: 'SE_Btn_Landing_CustPortal',
          category: 'SE_Btn_Landing_CustPortal'
        }
      }
    },
    NO: {
      LIVE_CHAT: {
        CLICK: {
          tagName: 'NO_Btn_Landing_LiveChat',
          category: 'NO_Btn_Landing_LiveChat'
        }
      },
      SERVICE_PORTAL: {
        CLICK: {
          tagName: 'NO_Btn_Landing_CustPortal',
          category: 'NO_Btn_Landing_CustPortal'
        }
      }
    },
    DK: {
      LIVE_CHAT: {
        CLICK: {
          tagName: 'DK_Btn_Landing_LiveChat',
          category: 'DK_Btn_Landing_LiveChat'
        }
      },
      SERVICE_PORTAL: {
        CLICK: {
          tagName: 'DK_Btn_Landing_CustPortal',
          category: 'DK_Btn_Landing_CustPortal'
        }
      }
    },
    FI: {
      LIVE_CHAT: {
        CLICK: {
          tagName: 'FI_Btn_Landing_LiveChat',
          category: 'FI_Btn_Landing_LiveChat'
        }
      },
      SERVICE_PORTAL: {
        CLICK: {
          tagName: 'FI_Btn_Landing_CustPortal',
          category: 'FI_Btn_Landing_CustPortal'
        }
      }
    }
  },

  [PARTNER_DETAILS.SHOPEE]: {
    TH: {
      SERVICE_PORTAL: {
        CLICK: {
          tagName: 'TH_Btn_Landing_CustPortal',
          category: 'TH_Btn_Landing_CustPortal'
        }
      }
    },
  },

  [PARTNER_DETAILS.HOMECREDIT]: {
    PH: {
      SERVICE_PORTAL: {
        CLICK: {
          tagName: 'PH_btn_HCLanding_LiveChat',
          category: 'PH_btn_HCLanding_LiveChat'
        }
      }
    },
  },

  [PARTNER_DETAILS.ELECBOY]: {
    HK: {
      LIVE_CHAT: {
        CLICK: {
          tagName: 'HK_btn_Landing_LiveChat',
          category: 'HK_btn_Landing_LiveChat'
        }
      }
    },
  },

};
